



























import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class FileInput extends Vue {
  @Prop() rules!: string;
  @Prop() name!: string;
  @Prop() type!: string;
  @Prop() src!: string;
  @Prop() accept!: string;
  @Prop() maxSizeImgInKb!: number;
  @Prop() maxSizeVideoInKb!: number;

  @Ref() fileChoose!: HTMLElement;
  onActive = true;
  onPreview = false;
  onPreviewVideo = false;
  modValue: string = "";
  srcPreview: string | ArrayBuffer | null = "";
  image = null;
  onElementClick() {
    if (this.fileChoose) {
      this.fileChoose.click();
    }
  }
  onSelectFile(payload) {
    const input = payload.target;
    const files = input.files;
    if (files && files[0]) {
      const reader = new FileReader();
      if (
        files[0].type === "video/mp4" ||
        files[0].type === "video/mkv" ||
        files[0].type === "video/mov"
      ) {
        if (this.fileMaxImage(files[0].size, this.maxSizeVideoInKb)) {
          reader.onload = (payload) => {
            if (payload.target) {
              this.srcPreview = payload.target.result;
              // console.log(payload.target.result, "video");
            }
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);
          this.onPreviewVideo = true;
          this.onPreview = false;
          this.onActive = false;
        }
      } else if (
        files[0].type === "image/png" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/jpeg"
      ) {
        if (this.fileMaxImage(files[0].size, this.maxSizeImgInKb)) {
          reader.onload = (payload) => {
            if (payload.target) {
              this.srcPreview = payload.target.result;
              // console.log(payload.target.result, "image");
            }
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);
          this.onPreview = true;
          this.onPreviewVideo = false;
          this.onActive = false;
        }
      }
    }
  }

  fileMaxImage(size: number, max: number) {
    const maxFile = max * 1000;
    if (size <= maxFile) {
      return true;
    } else {
      return false;
    }
  }
}
