export default class ListImageModel {
  id: string | null;
  fileImage: any;
  namaFile: string | null;
  deskripsi: string | null;
  constructor() {
    this.id = null;
    this.fileImage = null;
    this.namaFile = null;
    this.deskripsi = null;
  }
}
